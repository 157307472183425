<template lang="pug">
    .main-wrapper.resgate-creditos-list
        Dialog.dialogDetalhesResgate(:modal="true" header= "Anexos do correntista" :visible.sync="show")
            .p-grid.p-fluid
                .p-col-12(style="align-self: flex-end;")
                    label.form-label Comprovante:
                    ProgressBar(v-if="waitingComprovante" mode="indeterminate")
                    .p-inputgroup(v-else)
                        .p-input-icon-left.p-input-icon-right
                            InputText(
                                type="text"
                                placeholder="Anexar"
                                v-model="model.nm_comprovante"
                                @click="$refs.refComprovante.click()"
                                readonly)
                            i.jam.jam-attachment(@click="$refs.refComprovante.click()" v-tooltip.top="'Anexar'")
                        Button(
                            :disabled="!anexos.aq_anexo"
                            icon="pi pi-external-link"
                            v-tooltip.top="'Vizualizar anexo'"
                            @click="abrirComprovante(anexos.aq_anexo)")
    
                    input(
                        v-show='false'
                        type="file"
                        ref="refComprovante"
                        @change="nomeComprovante()"
                        accept=".")
    
                .p-col-12
                    Button(type='button' label='Ok' @click='$emit("close")')
</template>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
    import { Empresa } from "@/middleware"
    import InputNumber from 'primevue/inputnumber'

    // import { required, minValue } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Tooltip, Calendar, DataTable, Column, ConfirmDialog,
            InputNumber,
        },
        props: [
            'display',
            'anexos',
        ],
        computed: {
            show: {
                get() {
                    this.verificaAnexo()
                    return this.display;
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        directives: { tooltip: Tooltip },
        data () {
            return {
                model: {
                    aq_anexo: null,
                    nm_comprovante: '',
                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingComprovante: false,
                waitingForm: false,
                submitted: false,
            }
        },
        methods: {
            verificaAnexo() {
                if(this.anexos.aq_anexo) {
                    const aux1 = this.anexos.aq_anexo.lastIndexOf('/');
                    const aux2 = this.anexos.aq_anexo.lastIndexOf('?');
                    this.model.nm_comprovante = this.anexos.aq_anexo.substring(aux1 + 1, aux2);
                }
            },
            nomeComprovante() {
                const files = this.$refs.refComprovante.files;
                const comprovante = files.length && files[0];

                this.model.aq_anexo = comprovante;
                this.model.nm_comprovante = comprovante.name;
                this.salvarComprovante();
            },
            salvarComprovante() {
                this.waitingComprovante = true
                let dataSend = Object.assign({}, {
                    cd_titulo: this.anexos.id,
                    aq_comprovante_pagamento: this.model.aq_anexo,
                })
                Empresa.saveTituloContratante( dataSend ).then(response => {
                    this.waitingComprovante = false;
                    if (response.status === 200) {
                        this.anexos.aq_anexo = response.data.aq_anexo;
                        this.$toast.success(
                            "ANEXADO com sucesso."
                        );
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data){
                            Object.values(response.data).forEach(error => {
                                error.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            })
                        }
                    }
                });
            },
            abrirComprovante(params) {
                if (params)
                    window.open(params);
                else this.$toast.error("Link para o arquivo não encontrado.");
            },
        }
    }
</script>

<style lang="scss">
.resgate-creditos-list {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex {
                background-color: #e4f8e1;
            }
            &.a {
                background-color: #faf3dd;
            }
        }
        .p-progress-spinner {
            max-width: 23px;
            height: auto;
        }
    }
    .dialog-confirm {
        width: 300px;
        .p-dialog-content {
            padding-bottom: 6px;
        }
        .confirm-button {
            background-color: green;
        }
        .cancel-button {
            background-color: red;
            &:hover {
                background-color: rgb(122, 0, 0);
            }
        }
        .p-button.p-button-text {
            color: white;
            border-color: transparent;

            &:hover {
                background-color: transparent;
                color: black;
                border-color: black;
            }
        }
    }
    .dialogDetalhesResgate {
        width: 98%;
        max-width: 750px;
    }
    .highlight {
        background-color: #c9daf8;
        display: inline;
        font-weight: 700;

        &.fi {
            background-color: #c5efcb;
        }
        &.re {
            background-color: #f8d6d9;
        }
        &.so {
            background-color: #fff2cc;
        }
    }
    .text-small {
        .p-inputtext {
            font-size: 12px;
        }
        .form-label {
            font-size: 12px;
        }
    }
    .fieldset {
        border: 1px solid #c8c8c8;
    }
    .text-title {
        color: #657786;
        font-size: 14px;
        font-weight: 700;
    }
    .p-tooltip {
        max-width: none;
    }
    .waiting-small {
        max-width: 29px;
        height: auto;
    }
    .form-group--error .p-inputtextarea {
        border-color: #db5540;
    }
    .p-input-icon-left,
    .p-input-icon-right {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .p-input-icon-left > i,
    .p-input-icon-right > i {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }
    .p-input-icon-left > i:first-of-type {
        left: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-right > i:last-of-type {
        right: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2rem;
    }
    .p-input-icon-right > .p-inputtext {
        padding-right: 2rem;
    }
}
</style>